import styled from "@emotion/styled";
import { DialogContext, ExBottomSheet } from "@energyx-dev/energyx-ui";
import { useContext, useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import dayjs from "dayjs";
import { TransferInfoModal } from "components/TransferInfoModal";
import { TransferInfoModal2 } from "components/TransferInfoModal2";
import { Modal } from "components/Modal";

export const Layout = () => {
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isFirstModalShow, setIsFirstModalShow] = useState(true);
  const today = dayjs(dayjs().format("YYYY-MM-DD"));
  const limitDay = dayjs(dayjs("2023-12-24").format("YYYY-MM-DD"));

  useEffect(() => {
    if (isBrowser && today.isBefore(limitDay)) {
      setIsShow(true);
    } else if (
      !isBrowser &&
      today.isBefore(limitDay) &&
      !localStorage.getItem("doNotWatchTransferInfoModalAgain")
    ) {
      setShowBottomSheet(true);
    }
  }, []);
  return (
    <LayoutContainer>
      {isShow && (
        <Wrapper>
          <ModalWrapper>
            {isFirstModalShow && !localStorage.getItem("notFirstModal") && (
              <Modal
                title="공고"
                primaryLabel="확인"
                secondaryLabel="다시보지 않기"
                overflow="scroll"
                onSecondaryClick={() => {
                  localStorage.setItem("notFirstModal", "true");
                  setIsFirstModalShow(false);
                }}
                onPrimaryClick={() => setIsFirstModalShow(false)}
                onCloseClick={() => setIsFirstModalShow(false)}
              >
                <TransferInfoModal />
              </Modal>
            )}
          </ModalWrapper>
        </Wrapper>
      )}
      <ExBottomSheet
        primaryLabel="확인"
        secondaryLabel="다시 보지 않기"
        setShow={setShowBottomSheet}
        show={showBottomSheet}
        title={"양도・양수 공고"}
        overflow="scroll"
        onSecondaryClick={() => {
          setShowBottomSheet(false);
          localStorage.setItem("doNotWatchTransferInfoModalAgain", "true");
        }}
        onPrimaryClick={() => {
          setShowBottomSheet(false);
        }}
      >
        <TransferInfoModal2 />
      </ExBottomSheet>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;
  margin: 0px auto;
  max-width: 1248px;
  height: 100vh;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1000;
  gap: 27px;
  padding-left: 24px;
  padding-right: 24px;
`;
