import styled from "@emotion/styled";

export const TransferInfoModal2 = () => {
  return (
    <Layout>
      <img src="/png/temp1.png" />
    </Layout>
  );
};

const Layout = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  h4 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.2px;
  }
  p {
    margin-top: 16px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  a {
    display: inline-block;
    margin-top: 8px;
    color: var(--v-2-primary-primary-60, #7a37d8);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
  }
`;
