import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { DialogContext, ExButton } from "@energyx-dev/energyx-ui";
import { useContext } from "react";
import { isNil } from "lodash";

type ModalProps = {
  title: string;
  caption?: string;
  /**
   * Primary CTA 버튼의 라벨
   */
  primaryLabel: string;
  /**
   * Secondary CTA 버튼의 라벨
   */
  secondaryLabel?: string;
  /**
   * Primary CTA 버튼의 클릭이벤트 콜백 함수
   */
  onPrimaryClick: () => void;
  /**
   * Secondary CTA 버튼의 클릭이벤트 콜백 함수
   */
  onSecondaryClick: () => void;
  onCloseClick: () => void;
  children: React.ReactNode;
  overflow?: string;
  closeIconClassName?: string;
  secondaryClassName?: string;
  primaryClassName?: string;
};

export const Modal = ({
  title,
  caption,
  children,
  primaryLabel,
  secondaryLabel,
  overflow,
  closeIconClassName,
  primaryClassName,
  secondaryClassName,
  onSecondaryClick,
  onPrimaryClick,
  onCloseClick,
}: ModalProps) => {
  const { close } = useContext(DialogContext);

  return (
    <Wrapper>
      <Title>
        <div
          css={(theme) => css`
            display: flex;
            gap: 16px;
            .xicon-wrap {
              height: fit-content;
              cursor: pointer;
            }
            .xicon- {
              font-size: 24px;
              line-height: 24px;
              color: ${theme.color.mono[20]};
            }
          `}
        >
          <div
            css={(theme) =>
              css`
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 0 0 0 8px;
                gap: 8px;
                > .title {
                  color: ${theme.color.black};
                  ${theme.font.Title2_h};
                }
                > .caption {
                  color: ${theme.color.mono[60]};
                  ${theme.font.Caption};
                }
              `
            }
          >
            <span className="title">{title}</span>
            <span className="caption">{caption}</span>
          </div>
          <div
            className={`xicon-wrap ${closeIconClassName}`}
            role="button"
            tabIndex={0}
            css={css`
              display: flex;
            `}
            onKeyDown={() => onCloseClick()}
            onClick={() => onCloseClick()}
          >
            <span className="xicon-">close_style_border</span>
          </div>
        </div>
        <div
          css={(theme) =>
            css`
              width: 100%;
              height: 1px;
              background-color: ${theme.color.mono[10]};
            `
          }
        />
      </Title>
      <section
        css={css`
          flex: 1;
          overflow-y: ${overflow ? overflow : "unset"};
        `}
      >
        {children}
      </section>
      <section
        css={css`
          display: flex;
          justify-content: ${isNil(secondaryLabel) ? "center" : "space-between"};
        `}
      >
        {!isNil(secondaryLabel) && (
          <ExButton
            className={secondaryClassName}
            variant={"contents"}
            size={"large"}
            label={secondaryLabel}
            onClick={() => onSecondaryClick()}
          />
        )}
        <ExButton
          className={primaryClassName}
          variant={"contained"}
          size={"large"}
          label={primaryLabel}
          width={isNil(secondaryLabel) ? 185 : 106}
          onClick={() => onPrimaryClick()}
        />
      </section>
    </Wrapper>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Wrapper = styled.div`
  max-width: 500px;
  height: 700px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  overflow: scroll;
  color: ${({ theme }) => theme.color.mono[80]};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 16px;
  ${({ theme }) => theme.elevation.elevation4};
  ${({ theme }) => theme.breakpoints.down("DESKTOP")} {
    width: 368px;
    height: 400px;
  }
`;
