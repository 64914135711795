import "/public/ckeditor-content-style.css";
import "/public/style.css";
import "./styles/reset.css";
import "./styles/flex.scss";
import "./styles/marginPadding.scss";
import "./styles/common.scss";
import { Layout } from "components/layout/Layout";
import { ThemeProvider } from "@emotion/react";
import theme, { DialogContextProvider } from "@energyx-dev/energyx-ui";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <DialogContextProvider>
        <Layout />
      </DialogContextProvider>
    </ThemeProvider>
  );
};
export default App;
